.dashboard {
    width: 100%;
    margin-top: 50px;

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &-user {

        position: relative;
        flex-basis: 30%;
        margin: 0 auto 2rem;
        transition: all .3s ease-out;
        z-index: 100;
        padding-bottom: 0;
        background: transparent !important;

        &-age {
            display: block;
            bottom: 0;
            position: absolute;
            width: 100%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #212121;
            opacity: .5;
        }

        .message-send {
            position: absolute;
            background: #424242;
            width: 100%;
            bottom: 0;
            display: none;
        }

        form {
            margin: 0 10%;
            opacity: 0;
            left: 0;
            transition: all .3s ease-out;
            bottom: 2rem;
            z-index: 99;
        }

        &--inactive:hover {
            opacity: 1 !important;
            transform: scale(1.05);
        }
        
        &--active {
            background: #424242 !important;
            opacity: 1;
            transform: scale(1.05) translateY(-125px);
            z-index: 2000;

            &:after {
                opacity: 0;
            }

            .message-send {
                display: block;
                padding-top: 2rem;
                bottom: -250px;
            }    

            form {
                opacity: 1;
                z-index: 199;
            }
        }

        &--online {
            &:after {
                opacity: 0;
            }
        }
    
        &-title {
            background: #424242;
            background: linear-gradient(0deg, rgba(66,66,66,1) 70%, rgba(66,66,66,0) 100%);
            color: white;
            width: 100%;
            overflow: hidden;
        }
        
        &-image {
            float: left;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            overflow: hidden;
    
            &-wrapper {
                position: relative;
                overflow: hidden;
    
                &:before {
                    content: "";
                    float: left;
                    padding-top: 100%;
                    overflow: hidden; 	/* initial ratio of 1:1*/        
                }
            }
        }
    }    
}


