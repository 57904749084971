.openmap {
    width: 100%;
    height: 100%;
    height: 400px;
    overflow: hidden;

    &-wrapper {
        width: 100%;
        position: relative;
        margin-top: 1rem;

        fieldset {
            border: none;
            padding: 0;
            margin: 0;

            button {
                position: absolute;
                right: 0;
                bottom: 0.8rem;
                border: 0;
                background: transparent;
                margin: 0;
                padding: 0;
                color: white;
                cursor: pointer;
            }
        }
    }
}
