@import '../../utils/rainbow-animation.scss';

.under-construction {

    &:before {
        content: "";
        top: 0;
        left: 0;
        width: 300vw;
        height: 300vw;
        opacity: 0.4;
        @include rainbow(40deg, true);
        overflow: hidden;
    }

    .logo--pulse {
        .logo-wrapper {
            margin-left: -25%;
        }
    }

    .content-wrapper {
        transition: transform .6s ease-in-out;
        flex: 1 1 0%;
        top: 0;
        width: 200vw !important;
        position: fixed;
        padding: 5rem 60% 5rem;
        left: -50vw;
        background: transparent;
        background: linear-gradient(0deg, rgba(33,33,33,0) 0%, rgba(33,33,33,1) 10%, rgba(33,33,33,1) 90%, rgba(33,33,33,0) 100%);
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        .content {
            max-width: 80vw;
            margin: auto;
        }
    
        p:last-child {
            margin-bottom: 0;
        }
    }
    
    .overlay {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
    }
}