.message {

    &-list {
        width: 100%;

        .unread .message-header {
            font-weight: bolder;
        }    
    }

    &-header {
        width: 100%;
        position: relative;
        text-align: left;

        &-title {
            margin-right: 40px;
            font-size: 1.2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            height: 100%;

            &-user {
                margin-right: .5rem;
            }
        }
    
        &-delete {
            opacity: 0;
            transition: opacity 600ms ease-out;
            right: 0;
            top: 0;
            position: absolute;
        }
    


        &:hover &-delete,
        &:active &-delete {
            opacity: 1;
        }

        &-delete .material-icons {
            margin-right: 0;
        }
    }
}

.btn-delete {
    &-check,
    &-close {
        position: absolute;
        bottom: 1rem;
    }

    &-check {
        right: 1rem;
    }

    &-close {
        left: 1rem;
    }    
}
