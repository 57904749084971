@mixin rainbow-background($angle) {
    background: transparent; /* For browsers that do not support gradients */
    background: linear-gradient(
        $angle,
        violet,
        red, red,
        orange, orange,
        yellow, yellow,
        green, green,
        cyan, cyan,
        blue, blue,
        violet
    );
}

@mixin rainbow($angle, $isAnimated) {
    @if $isAnimated {
        @include rainbow-background(0deg);
        animation: slide 12s infinite linear;
        position: fixed;
        transform-origin: top;
        transform: rotate($angle);
    } @else {
        @include rainbow-background($angle);
    }
}

@mixin rainbow-text($angle) {
    @include rainbow($angle, false);
    -webkit-background-clip: text;
    background-clip: content-box;
    color: white;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

@keyframes slide {
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 300vw 300vw;
    }
   
   }
   