@import './utils/rainbow-animation.scss';

.grad {
    &-vertical,
    &-horizontal {
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
    }

    &-vertical {
        background: linear-gradient(
            180deg,
            rgba(33,33,33,1) 100px,
            rgba(33,33,33,0) 200px
        );
    }

    &-horizontal {
        background: linear-gradient(
            90deg,
            rgba(33,33,33,0) 0%,
            rgba(33,33,33,1) 10%,
            rgba(33,33,33,1) 90%,
            rgba(33,33,33,0) 100%
        );
    }
}

.app {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 100vh;
}

#root {
    &:before {
        content: "";
        top: 0;
        left: 0;
        z-index: -1;
        width: 300vw;
        height: 300vw;
        opacity: 0.4;
        @include rainbow(40deg, true);
    }
}

.content-root {
    padding: 1rem;
    z-index: 10;
    overflow-x: hidden;
}

.message-icon {
    height: 40px;
    width: 40px;
    margin-right: 1rem;
    border-radius: 50%;
    object-fit: cover;
}

.webcam-video {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 320px;
    height: 240px;
    z-index: 1000;
}

textarea#description {
    padding-bottom: 3.5rem;
}

.btn.primary {
    text-align: center;
    width: auto;
    padding-left: 28px;
}

.btn-floating i {
    color: black;
}

.dropdown-content .btn {
    text-align: left;
}

.dropdown-content .btn.primary {
    text-align: left;
    width: 100%;
}

.dropdown-content li:hover {
    background-color: #212121;
    color: #ffc107;
}

.dropdown-content {
    background: #212121;
}

.modal .modal-footer {
    background: #424242;
}

.collapsible-header {
    background: #616161;
}

.collapsible, .collapsible-header, .collapsible-body {
    border-color: #424242;
}

.input-field input,
.input-field textarea {
    color: white;
}

/* label focus color */
.input-field input:focus + label,
.input-field textarea:focus + label {
    color: #ffc107 !important;
}
/* label underline focus color */
.input-field input:focus,
.input-field textarea:focus {
    border-bottom: 1px solid #ffc107 !important;
    box-shadow: 0 1px 0 0 #000;
}
/* valid color */
.input-field input.valid,
.input-field textarea.valid {
    color: white;
    border-bottom: 1px solid white;
    box-shadow: 0 1px 0 0 #000;
}
/* invalid color */
.input-field input.invalid
.input-field textarea.invalid {
    color: red;
    border-bottom: 1px solid red;
    box-shadow: 0 1px 0 0 #000;
}
/* icon prefix focus color */
.input-field .prefix.active {
    color: #ffc107;
}

img {
    image-orientation: from-image;
}
