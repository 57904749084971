@import '../../utils/rainbow-animation.scss';

.logo {
    display: flex;
    width: max-content;
    flex-direction: column;
    font-family: monospace;
    margin: 0;
    line-height: 1;
    text-shadow: 0 0 .2em #000;
  
    &-wrapper {
        display: flex;
        justify-content: center;
    }

    &-fuke {
        font-size: 1.5rem;
    }

    &-sen {
        font-size: 3rem;
    }

    &--pulse {
        position: absolute;
        z-index: -1;
        margin-top: 5rem;
        animation: logoPump 2s infinite;
    }
}

@keyframes logoPump {
    0%, 100% {
        opacity: .4;
        transform: scale(3);
    }
    50% {
        opacity: 0;
        transform: scale(4);
    }
  }