.row.navbar-fixed {
    width: 100vw;
    height: 6rem;

    .header-nav {
        height: 100px;
        left: 0;
        top: 0;
        width: 100vw;

        @media only screen and (min-width: 1281px) {
            margin: 0 calc((100vw - 1280px) / 2);
        }
        
        &-wrapper {
            height: 100px;
            padding: 1.5rem;
            display: flex;
        }
        
        @media only screen and (max-width: 992px) {
            h4 {
                display: inline-block;
            }
        }

        &-desktop {
            display: flex;
            flex: 1;

            a.btn {
                padding-right: 10px;
                padding-left: 10px;
                order: 99;
            }
            
            a.btn.active.white-text {
                order: 1;
                flex: 1;
                color: #ffc107 !important;
                font-size: 2rem;
            }
            
            button {
                margin-left: 10px;
                order: 99;
            }    
        }
        
        nav .brand-logo {
            position: relative;
        }
        
        
        .mobile-title {
            display: flex;
            flex: 1;
            justify-content: center;
        }
                
        .message-counter {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            position: relative;
            margin-left: -.2rem;
            top: -.8rem;
            margin-right: -1rem;
            font-size: .8rem;
            background-color: black;
            font-weight: bold;
            border-radius: 50%;
            text-align: center;

            @media only screen and (max-width: 992px) {
                top: -.1rem;
            }

            &-label {
                display: flex;
            }
        }      
    }    
}

