.chat {
    width: 100%;
    z-index: 20;

    &-input {
        position: fixed;
        padding: 1rem;
        bottom: 0;
        display: flex;
        flex-direction: row;
        z-index: 1000;
        height: 7rem;

        .col.s10 {
            flex: 1;
            padding-right: 2rem;
        }
    }
    
    &-list-wrapper {
        padding-bottom: 9rem;
        padding-top: 2.5rem;
    }

    ul.chat-list {
        margin-bottom: 3rem;
        padding: 3rem;
        border-radius: .2rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);

        li {
            text-align: left;
        }

        .chat-user ~ ul.chat-list {
            margin-top: -50px;
        }
    }

    .chat-list-row:last-child ul.chat-list {
        margin-bottom: -4rem;
    }

    &-entry {

        &-date {
            opacity: .5;
            font-size: .8rem;
            margin-right: .5rem;
        }
    }
    
    
    & &-user {
        font-size: .8rem;
        margin-top: -2.5rem;

        &-label {
            padding: .5rem;
        }

        .message-icon {

            margin-right: 0;

            @media only screen and (max-width: 992px) {
                width: 80px;
                height: 80px;
                margin-bottom: -65px;
            }
        
            @media only screen and (max-width: 600px) {
                width: 75px;
                height: 75px;
                margin-bottom: -50px;
            }        
        }

        &--left {
            float: left;
            margin-left: 10%;
            margin-right: 0;

            @media only screen and (max-width: 992px) {
                margin-left: 0;
            }
            
            .chat-user-label {
                float: right;
            }
        }

        &--right {
            float: right;
            margin-left: 0;
            margin-right: 10%;

            .chat-user-label {
                float: left;
            }
        }
    }
        
    &-room-selector-mobile {
        position: fixed;
        bottom: 6rem;
        right: 6rem;
        z-index: 2000;

        @media only screen and (max-width: 600px) {
            right: 3rem;
        }

        button img {
            height: 100%;
            object-fit: cover;
        }
    }
    
    &-room-selection-item {
        padding-left: 45px !important;
        color: white !important;

        i,
        img {
            margin-left: -32px !important;
            margin-right: 0 !important;
        }
        
        .message-icon {
            margin-left: 0 !important;
            height: 25px !important;
            width: 25px !important;
            margin-bottom: 0 !important;
            position: absolute;
            left: 12px;
        }
    }    

    .message-icon {
        width: 100px;
        height: 100px;
        margin-bottom: -75px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    }
}


